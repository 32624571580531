import * as React from 'react';
import { StyleSheet, Text, View, Dimensions, SafeAreaView, Linking } from 'react-native';

import { Provider } from 'react-native-paper';

const { width, height } = Dimensions.get("window");

//import { theme as tema } from "../../theme";

import CountDown from 'react-native-countdown-component';

import * as Font from 'expo-font';
import { Silkscreen_400Regular } from '@expo-google-fonts/silkscreen';
let customFonts = {
  "Silkscreen_400Regular": Silkscreen_400Regular,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        fontsLoaded: false,
        seconds: undefined,
    };
  }

  async _loadFontsAsync() {
    await Font.loadAsync(customFonts).then(()=>{
      this.setState({ fontsLoaded: true });
    })
  }

  componentDidMount(){
    this._loadFontsAsync()
    this.getTimeLeft()
  }

  openUrl(){
    Linking.openURL('mailto:2004williambestw@gmail.com')
  }

  openUrl2(){
    Linking.openURL('https://www.buymeacoffee.com/bilbam04')
  }

  getTimeLeft(){
    const milliLeft = 1687489200000 - Date.now()
    const secLeft = milliLeft / 1000
    this.setState({seconds:secLeft})
    console.log(secLeft)
  }

  render() {
    const { fontsLoaded } = this.state

    if(fontsLoaded===false){
        return(<View></View>)
    }else{
    return (

        <>
            <head>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9067976629440223"
                crossorigin="anonymous">
            </script>
            
            <script type="text/javascript" src="https://cdnjs.buymeacoffee.com/1.0.0/button.prod.min.js" data-name="bmc-button" data-slug="bilbam04" data-color="#5F7FFF" data-emoji="❤️"  data-font="Lato" data-text="Buy me a coffee" data-outline-color="#000000" data-font-color="#ffffff" data-coffee-color="#FFDD00" ></script>

            </head>

            <style
                dangerouslySetInnerHTML={{
                __html:

                    `
                        .bg{
                            height:100%;
                            width:100%;
                            background-image: linear-gradient(
                                0deg,
                                hsl(243deg 95% 7%) 0%,
                                hsl(237deg 87% 13%) 28%,
                                hsl(231deg 81% 18%) 56%,
                                hsl(224deg 74% 24%) 74%,
                                hsl(218deg 68% 31%) 85%,
                                hsl(211deg 63% 38%) 92%,
                                hsl(205deg 58% 45%) 97%,
                                hsl(199deg 58% 52%) 99%,
                                hsl(192deg 73% 60%) 100%,
                                hsl(186deg 96% 69%) 100%
                            );
                        }

                        .transparent-gradient {
                            z-index:1;
                            -webkit-mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
                            mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))
                          }

                        .imgsub{
                            width:100%;
                            position:absolute;
                            bottom:0px;
                            z-index:0;
                        }

                        .title{
                            z-index:2;
                            font-family: Silkscreen_400Regular;
                            font-size: 4vh;
                            color:#11283d;
                            -webkit-text-stroke-width: 1px;
                            -webkit-text-stroke-color: orange;
                            padding:10px;
                            text-align:center;
                        }

                        .subtitle{
                            z-index:2;
                            font-family: Silkscreen_400Regular;
                            font-size: 2vh;
                            color:#black;
                            -webkit-text-stroke-width: 0.5px;
                            -webkit-text-stroke-color: orange;
                            padding:10px;
                        }

                        .body{
                            font-family: Silkscreen_400Regular;
                            font-size: 1em;
                            color:#FFF;
                            padding:10px;
                        }

                        .link{
                            font-family: Silkscreen_400Regular;
                            font-size: 3vw;
                            color:#11283d;
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        .linkText{
                            font-family: Silkscreen_400Regular;
                            font-size: 3wv;
                            color:#11283d;
                        }

                    `
                }}

            />

        <Provider>
        <SafeAreaView style={{ flex:1, backgroundColor: "#000" }}>
        <View style={{ flex:1, position:'absolute', zIndex:1, justifyContent:'center', height:'100%', width:'100%'}}>
         
            <View style={{flex:1}}>
            <div className="bg">

                <View style={{flex:1,justifyContent:'center',alignItems:'center',width:width,height:height}}>

                    <View style={{flex:0.2}}><Text></Text></View>

                    <span className='title'>Missing Titanic Submersible</span>
                    <span className='subtitle'>Live Oxygen Countdown</span>

                    
                    <View style={{flex:0.5, justifyContent:"center", alignItems:'center'}}>
                        <span className='body'>Reported missing: <br></br> Sunday morning at aprox. 12,500 feet below the surface</span>
                    </View>

                    <View style={{flex:1}}>
                        <CountDown
                            until={this.state.seconds}
                            //onFinish={() => alert('finished')}
                            //onPress={() => alert('hello')}
                            size={width*0.07}
                        />
                        <span className='body'>Aproximation based on the 96-hour emergency air supply</span>
                    </View>

        

                </View>

        
            
            <View style={{width:"100%", position:"absolute", top:0, padding:5}}>
                <span className='linkText'>Contact developer William Apelgren:</span>
                <span onClick={()=>this.openUrl()} className='link'>2004williambestw@gmail.com</span>
            </View>

            <View style={{width:"100%", position:"absolute", top:0, padding:5, right:0}}>
                <span onClick={()=>this.openUrl2()} className='link'>Buy me a coffee!</span>
            </View>
        
            <img className="transparent-gradient imgsub" src="https://firebasestorage.googleapis.com/v0/b/titanicsubcount.appspot.com/o/img.jpg?alt=media&token=dcfc94bb-b015-42d7-ba45-4f6a354aa395">
            </img>
            </div>
            </View>

        </View>
        </SafeAreaView>
        </Provider>

       

           

            



        </>
        
    );}
  }
}

export default App;


